import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/opt/build/repo/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/(elty)/(virtualCare)/layout.tsx\",\"import\":\"Plus_Jakarta_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"weight\":\"variable\",\"variable\":\"--font--plusjakartasans\"}],\"variableName\":\"plusJakartaSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["CookieFooterButton"] */ "/opt/build/repo/src/app/_components/layout/footer/cookieFooterButton.tsx");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/_components/zendesk.tsx");
