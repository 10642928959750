import { clientEnv } from '@/env/client';

type CloudflareLoader = {
  src: string;
  width: number;
  quality?: number;
};

const normalizeSrc = (src: string) => {
  return src.startsWith('/') ? src.slice(1) : src;
};

const cloudflareLoader = ({ src, width, quality = 90 }: CloudflareLoader) => {
  if (clientEnv.NEXT_PUBLIC_DISABLE_CLOUDFLARE_LOADER) {
    return src;
  }
  const params = [`width=${width}`, 'format=auto'];
  if (quality) {
    params.push(`quality=${quality}`);
  }
  const paramsString = params.join(',');

  // The domain is not based on the enviroment but is fixed: davincisalute.com
  return `https://elty.it/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`;
};

export default cloudflareLoader;
